import Parse from 'parse'
import moment from 'moment'
import { navigate } from 'gatsby'
import axios from 'axios'

//Initialize Parse
Parse.initialize(
  '7imZ6mICoVo0p8UO9XkK03gZpsueTLH0NpSgtQuy',
  'jdNqCWzJRciqo2EOu6SGhudO6qHCbfvLS99Yxzcr'
)
Parse.serverURL = 'https://parseapi.back4app.com'

//Used to optimize images being returned from Parse for the client
const manipulation =
  'https://res.cloudinary.com/dn2myo4zr/image/fetch/w_250,h_250,f_auto,q_auto:eco/'
const fullManipulation =
  'https://res.cloudinary.com/dn2myo4zr/image/fetch/w_550,h_550,f_auto,q_auto:eco/'

const BASE_URL = 'https://devapi.wheredpump.com'
// const BASE_URL = "http://localhost:3000";

/*****************************************/
///////////////////TICKETS/////////////////
/*****************************************/
export async function GetUserTickets(userId) {
  try {
    const response = await axios.get(`${BASE_URL}/ticketholder/${userId}`)

    const results = response
    console.log(results)
    return results
  } catch (e) {
    console.log(e)
    return []
  }
}

export async function CreateTicketTypes(tickObjs, eventId, eventName) {
  console.log('entered create tickets')

  // ADD EVENT NAME ATTRIBUTE TO EACH TICKET OBJECT
  tickObjs = tickObjs.map(t => {
    return {
      ...t,
      eventName,
    }
  })

  try {
    // MAKE CALL TO API
    const response = await axios.post(`${BASE_URL}/tickets/${eventId}`, {
      data: {
        ticketsObj: tickObjs,
      },
    })
    // CHECK IF RESPNSE IS 200(OK)
    if (response.status === 200) {
      console.log('create tick response ', response)
      // NAVIGATE TO SUCCESS SCREEN
      navigate('/dashboard/overview', {
        state: { tickets: response.data.ticketsObj },
      })
    } else {
      console.log('Bad response', response.status)
      console.log(response)
    }
  } catch (e) {
    console.log(e)
    console.log(e.message)
  }
}

export async function GetTicketTypes(eventId) {
  try {
    //Make call to api
    const response = await axios.get(`${BASE_URL}/tickets/${eventId}`)

    if (response.status === 200) {
      console.log(response.data)
      return response.data
    } else {
      console.log('Something went wroong: ' + response.status)
    }
  } catch (err) {
    console.log('Catch error: ' + err)
  }
}

export async function CreateTicketHolder(
  tickObjs,
  event,
  fullName,
  email,
  ticketCounts
) {
  console.log('We have begun to assign tickets to holders')

  try {
    const response = await axios.post(`${BASE_URL}/ticketholder/${event.id}`, {
      data: {
        ticketsObj: tickObjs,
        fullName: fullName,
        email: email,
        ticketCounts: ticketCounts,
        event: event,
      },
    })
    // CHECK IF RESPNSE IS 200(OK)
    if (response.status === 200) {
      console.log('ticket holder response ', response)
      var { createdTickets } = response.data
      console.log(createdTickets)
      //SEND USER EMAIL WITH TICKET INFO
      const emailResp = await axios.post(`${BASE_URL}/send-email`, {
        data: {
          createdTickets: createdTickets,
          pump: event,
          fullName: fullName,
          email: email,
        },
      })
      console.log('email sent, reduce counts')
      //Now let's reduce ticket counts
      reduceCounts(ticketCounts)
    } else {
      alert('Something went wrong')
      console.log('Bad response', response.status)
      console.log(response)
    }
  } catch (e) {
    console.log(e.message)
  }
}

async function reduceCounts(ticketCounts) {
  console.log('reducing counts')
  const resp = await axios.post(`${BASE_URL}/ticket-count`, {
    data: {
      ticketCounts: ticketCounts,
    },
  })
  console.log(resp)
  if (resp.status === 200) {
    console.log('Successfully reduced counts')
    // NAVIGATE TO SUCCESS SCREEN
    navigate('/payment-success')
  } else {
    //error in updating ticket counts
    console.log('something went wrong in updating counts')
  }
}

/*****************************************/
///////////////////DASHBOARD/////////////////
/*****************************************/
export async function GetTicketStats(eventId) {
  const Tickets = Parse.Object.extend('TG100')
  const query = new Parse.Query(Tickets)

  var ticketStats = []

  //set query terms to capture the pump owned by the user
  query.equalTo('eventId', eventId)
  query.equalTo('verified', true)

  //Get dem tickets
  const results = await query.find()
  var tickets = []
  for (let i = 0; i < results.length; i++) {
    var obj = results[i]
    tickets.push({
      id: obj.id,
      ticketType: obj.get('ticketType'),
      batchSize: obj.get('batchSize'),
      ticketCount: obj.get('ticketCount'),
      ticketPrice: obj.get('ticketPrice'),
    })
  }

  var ticketsSold = 0
  var netSales = 0
  var batchSize = 0

  tickets.forEach(ticket => {
    var sold = ticket.batchSize - ticket.ticketCount
    ticketsSold += ticket.batchSize - ticket.ticketCount
    var sales = (sold * ticket.ticketPrice) / 100.0
    netSales += sold * ticket.ticketPrice
    batchSize += ticket.batchSize
    console.log(
      `sold ${sold} ${ticket.ticketType} tickets / ${ticket.batchSize}`
    )

    ticketStats.push({
      ticketType: ticket.ticketType,
      batchSize: ticket.batchSize,
      ticketsSold: sold,
      ticketSales: sales,
      ticketPrice: ticket.ticketPrice,
    })
  })

  ticketStats.push({
    ticketsSold: ticketsSold,
    netSales: netSales,
    totalBatchSize: batchSize,
  })

  console.log(
    `sold ${ticketsSold} tickets with a net sale of $${netSales / 100}`
  )
  console.log(ticketStats)

  return ticketStats
}

export async function GetUserEvents(userId) {
  console.log('in get user events')
  try {
    const response = await axios.get(`${BASE_URL}/pumps?userId=${userId}`)
    const results = response.data
    var pumps = formatPumps(results)
    return pumps
  } catch (e) {
    console.log(e)
    return []
  }
}

/*****************************************/
///////////////////USER///////////////////
/*****************************************/

export function currentUserCheck() {
  return axios.get(`${BASE_URL}/auth/verifyToken`)
}

export function loginUser(email, password) {
  return axios.post(`${BASE_URL}/auth/login`, {
    email,
    password,
  })
}

export function signupUser(email, password, fullName, gender) {
  return axios.post(`${BASE_URL}/auth/signup`, {
    email,
    password,
    fullName,
    gender,
  })
}

/*****************************************/
///////////////////PUMPS///////////////////
/*****************************************/

export async function GetPump(eventId) {
  console.log('getting pump ' + eventId)
  try {
    const response = await axios.get(`${BASE_URL}/pumps/${eventId}`)
    const results = response.data
    var pumps = formatPumps([results])
    console.log('GetPump():', pumps)
    return pumps[0]
  } catch (e) {
    console.log(e)
    return {}
  }
}

//Function to get Some Pumps
export async function GetLimitedPumps() {
  try {
    const response = await axios.get(`${BASE_URL}/pumps?limit=8`)
    const results = response.data
    var pumps = formatPumps(results)
    return pumps
  } catch (e) {
    console.log(e)
    return []
  }
}

//Function to get ALL Pumps
export async function GetPumps() {
  try {
    const response = await axios.get(`${BASE_URL}/pumps?limit=200`)
    const results = response.data
    var pumps = formatPumps(results)
    return pumps
  } catch (e) {
    console.log(e)
    return []
  }
}

export const formatPumps = pumpArray => {
  var pumps = []
  for (let i = 0; i < pumpArray.length; i++) {
    var object = pumpArray[i]

    //Check pricing and format
    if (object.price === 1) {
      object.price = "TBA"
    } else if (object.price === 0) {
      object.price = "FREE"
    } else {
      object.price = `$${object.price}`
    }


    // console.log(object.date.iso)
    pumps.push({
      id: object.objectId,
      name: object.name,
      originalFlyer: fullManipulation + object.flyer.url,
      flyer: manipulation + object.flyer.url,
      date: moment(object.date.iso)
        .add(4, 'h')
        .format('MMMM Do, h:mm a'),
      price: object.price,
      details: object.Details,
      location: object.location,
      tags: object.tags,
      hasTickets: object.hasTickets,
      views: object.views,
    })
  }
  return pumps
}

export async function addEvent(name, location, price, date, flyer, details) {
  //Set up Parse database instance
  const Pumps = Parse.Object.extend('Pumps')
  const pump = new Pumps()

  //Create the event flyer
  let parseFile = new Parse.File('flyer.png', flyer)

  //Format the date
  let eventDate = moment(date)
    .subtract(4, 'hours')
    .toDate()

  var returnVal

  //Get event information
  pump.set('name', name)
  pump.set('Details', details)
  pump.set('verified', false)
  pump.set('price', parseInt(price))
  pump.set('date', eventDate)
  pump.set('location', location)
  pump.set('country', 'Trinidad')
  pump.set('hasList', false)
  pump.set('flyer', parseFile)
  pump.set('hasTickets', false)
  // pump.set("submittedBy", req.session.user.objectId);
  pump.set('views', 0)
  pump.set('attending', 0)
  pump.set('show', true)

  await pump.save().then(
    savedEvent => {
      console.log(`Saved event with id: ${savedEvent.id}`)
      returnVal = 200
    },
    err => {
      console.log(`Something went wrong, ${err.message}`)
      returnVal = err
    }
  )

  if (returnVal == 200) {
    return true
  } else {
    return returnVal.message
  }
}

//ANALYTICS FUNCTIONS 📈

export async function GetNewUsers() {
  var date = new Date()
  var dateAhead = new Date(date)
  date.setHours(0, 0, 0, 0)
  var statsObj = []

  //Start at today's date and go back 7 days iteratively to get analytics for each day
  for (let i = 0; i < 7; i++) {
    let query = new Parse.Query(Parse.User)

    //check if first run
    if (i !== 0) {
      date = moment(date)
        .subtract(1, 'days')
        .toDate()
    }

    // console.log("Formatted date: " + date)
    dateAhead = moment(date)
      .add(1, 'days')
      .toDate()
    // console.log("Formatted date ahead: " + dateAhead)

    //set query terms
    query.greaterThanOrEqualTo('createdAt', date)
    query.lessThan('createdAt', dateAhead)

    query.limit(10000)

    const res = await query.find()

    let totalUsers = res.length

    // console.log(`Events viewed ${date} - ${dateAhead} iOS:  ${iosRes.length}`)
    // console.log(`Events viewed ${date} - ${dateAhead} Android:  ${droidRes.length}`)
    // console.log("Events viewed this day total: " + (iosRes.length + droidRes.length))

    //Push to stats stack
    statsObj.push({
      totalUsers: totalUsers,
      date: moment(date).format('MMM Do'),
    })
  }

  return statsObj
}

export async function GetTotalEventViews() {
  const Analytics = new Parse.Object.extend('Analytics')

  var statsObj = []
  var date = new Date()
  var dateAhead = new Date(date)
  date.setHours(0, 0, 0, 0)

  //Start at today's date and go back 7 days iteratively to get analytics for each day
  for (let i = 0; i < 7; i++) {
    let iOSQuery = new Parse.Query(Analytics)
    let droidQuery = new Parse.Query(Analytics)

    //check if first run
    if (i !== 0) {
      date = moment(date)
        .subtract(1, 'days')
        .toDate()
    }

    // console.log("Formatted date: " + date)
    dateAhead = moment(date)
      .add(1, 'days')
      .toDate()
    // console.log("Formatted date ahead: " + dateAhead)

    //set query terms
    iOSQuery.greaterThanOrEqualTo('createdAt', date)
    droidQuery.greaterThanOrEqualTo('createdAt', date)
    iOSQuery.lessThan('createdAt', dateAhead)
    droidQuery.lessThan('createdAt', dateAhead)

    iOSQuery.equalTo('eventMessage', 'user is viewing event')
    droidQuery.equalTo('eventMessage', 'click event triggered')

    iOSQuery.limit(10000)
    droidQuery.limit(10000)

    const iosRes = await iOSQuery.find()
    const droidRes = await droidQuery.find()

    let iosViews = iosRes.length
    let droidViews = droidRes.length
    let totalViews = droidViews + iosViews

    // console.log(`Events viewed ${date} - ${dateAhead} iOS:  ${iosRes.length}`)
    // console.log(`Events viewed ${date} - ${dateAhead} Android:  ${droidRes.length}`)
    // console.log("Events viewed this day total: " + (iosRes.length + droidRes.length))

    //Push to stats stack
    statsObj.push({
      totalViews: totalViews,
      iosViews: iosViews,
      droidViews: droidViews,
      date: moment(date).format('MMM Do'),
    })
  }

  return statsObj
}

import mixpanel from 'mixpanel-browser';
mixpanel.init('9fec116c1359bc385c020e3802f25fe0');
console.log("mixpanel initiated")

let env_check = process.env.NODE_ENV === 'production';
console.log("env check: " + env_check)

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;
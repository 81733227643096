import React, { Component } from 'react';
import Layout from '../components/layout';

//NPM
import { Elements, StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components'
import CheckoutForm from '../components/checkout-form';
import { Link, replace } from 'gatsby';
import { Table } from 'reactstrap'
import { FaStripe, FaShoppingCart } from 'react-icons/fa'
import Fade from 'react-reveal/Fade';   
import moment from 'moment'

//Parse
import { currentUserCheck, CreateTicketHolder } from '../services/parse-bridge'
import { navigate } from '@reach/router';

var x

const CheckoutContainer = styled.div`
    max-width: 800px;
    padding: 90px 10%;
    box-shadow: 0 30px 40px rgba(103, 103, 103, 0.1);
    border: 1px solid #f5f5f5;
    border-radius: 15px;
    margin: 50px auto;
`
const PumpInfoContainer = styled.div``
const TableContainer = styled.div`
    overflow-x: auto;
`
const PumpTitle = styled.h1`
    font-size: 32px;
    line-height: 32px;
    font-family: 'Poppins';
`
const PumpDate = styled.h3`
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 100;
    margin: 0 0 7px 0;
    color: #4c4c4c;
`
const PumpLocation = styled.h5`
    color: #613838;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: bold;
`

const PumpFlyer = styled.img`
    width: 90px;
    height: 90px;
    object-fit: cover;
    margin: 10px auto;
    border-radius: 50%;
`

const OrderSummaryContainer = styled.div``
const PaymentInformationContainer = styled.div`
    display: block;
`

const Heading = styled.th`
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 12px;
    color: #0b1c44;
`

const Detail = styled.td`
    font-size: 15px;
    font-family: 'Poppins';
`

const BreakdownGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 248px;
    float: right;
    
`

const Subtotal = styled.h1`
    text-align: right;
    font-size: 15px;
    color: #6BC940;
    font-weight: 600;
    margin: 10px;
    text-align: ${props=> props.left ? 'left' : 'right'};

`

const SubtotalPre = styled.h1`
    text-align: ${props=> props.left ? 'left' : 'right'};
    font-size: 13px;
    color: #424242;
    font-weight: 500;
    margin: 10px;
`

const SectionTitle = styled.h1`
    font-size: 20px;
    font-family: 'Poppins';
    margin-bottom: 5px;
    color: #FC6247;
`

const AccentTitle = styled.h1`
    text-transform: uppercase;
    font-size: 15px;
    color: #FC6247;

    ::after {
        content: '';
        text-align: left;
        height: 5px;
        display: block;
        width: 50px;
        margin: 10px 0 20px 0;
        background-color: #fc444e;
    }
`

const Subtitle = styled.p`
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 100;
    font-style: italic;
    color: #6d6d6d;

    ::after {
        content: '';
        text-align: left;
        height: 5px;
        display: block;
        width: 80px;
        margin: 10px 0 15px 0;
        background-color: #fc444e;
    }
`

const GeneralForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 700px;
`
const FormInput = styled.input`
    padding: 10px;
    margin: 5px 0 20px 0;
    border: 1px solid #eee;
    border-radius: 3px;

    &:focus {
        outline: none;
    }
`
const FormLable = styled.label`
    text-transform: uppercase;
    font-size: 12px;
    font-family: Avenir-Black;
    opacity: 0.9;
`

const PaymentDisclaimer = styled.p`
    text-align: center;
    color: lightgray;
    padding: 0 20%;
    margin: 0 auto;
    max-width: 500px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
`

const TotalDisclaimer = styled.p`
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin-right: 10px;
`

const Clear = styled.div`
    clear: both;
`

const TimerContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    background-color: #fcf8e3;
    padding: 15px 20px;
    border-radius: 5px;
    color: #545454;
    border: 1px solid #faebcc;
    margin: 0 0 20px 0;
    align-items: center;
    display: flex;
`
const TimerInfo = styled.p`
    margin: 0;
    font-size: 15px;
    display:flex;
    text-align: right;
`
const Timer = styled.span`
    display: flex;
    font-size: 30px;
    margin-bottom: 0;
    margin-right: 5px;
`

class CheckoutPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fullName: "",
            email: "",
            minutes: "10",
            seconds: "00"
        }

        this.handleInput = this.handleInput.bind(this)

    }

    componentDidMount() {
        this.startTimer()
        currentUserCheck().then(data=>{
            let userData = data.data

            //Prefill the full name and email fields for user
            this.setState({ fullName: userData.fullName, email: userData.email })
        })
    }

    componentWillUnmount() {
        clearInterval(x)
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    startTimer() {
        //Get the time now
        let now = new Date().getTime()

        //Set the deadline time to 10 minutes from now
        let deadline = moment(now).add(10, 'minutes').toDate()

        x = setInterval(()=> {
            now = new Date().getTime()
            //Calculate the remaining time and display the result
            let timeRemaining = deadline.getTime() - now
            let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

            this.setState({ minutes: minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}), seconds: seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})})

            //Display alert when the countdown is over
            if (timeRemaining < 0) {
                clearInterval(x);
                this.setState({ minutes: "00", seconds: "00"})

                alert("You've exceeded the time limit so we have removed your spot in line.\n\nThe purpose of this time limit is to make purchasing fair and available to as many people as possible. Thank you for understanding. ")
                
                if (this.props.location.state) {
                    navigate(`/events/${this.props.location.state.pump.id}`, {replace: true})
                }
                
            }
        },1000)

        
    }

    render() {
        if (this.props.location.state) {
            const pump = this.props.location.state.pump
            const tickets = this.props.location.state.tickets
            const ticketsToPurchase = this.props.location.state.ticketsToPurchase
            const total = (this.props.location.state.subtotal * 1.12)
            return (
                <Layout>
                    <StripeProvider apiKey="pk_test_sAqWADTN9beGyfnIt0ZXOwuA">
                        <CheckoutContainer>
                        <TimerContainer>
                            <TimerInfo>
                                <Timer>{this.state.minutes}:{this.state.seconds}</Timer>  <div>- Your order is being held for 10 minutes</div>
                            </TimerInfo>
                        </TimerContainer>
                        <AccentTitle><FaShoppingCart />  Secure Checkout</AccentTitle>
                            {
                                pump &&
                                <PumpInfoContainer>
                                    <PumpFlyer src={pump.flyer}/>
                                    <PumpTitle>{pump.name}</PumpTitle>
                                    <PumpDate>{pump.date}</PumpDate>
                                    <PumpLocation>{pump.location}</PumpLocation>
                                    <hr style={{ opacity: '0.3', margin: '60px 0', clear: 'both' }} />
                                </PumpInfoContainer>
                            }
                            <Fade bottom>
                            <OrderSummaryContainer>
                                <SectionTitle>Order Summary</SectionTitle>
                                <Subtitle>Here's a breakdown and pricing of the ticket's you've selected</Subtitle>
                                <TableContainer>
                                    <Table style={{ maxWidth: '700px', margin: '10px auto' }}>
                                        <thead>
                                            <tr>
                                                <Heading>Ticket Type</Heading>
                                                <Heading>Price</Heading>
                                                <Heading>Quantity</Heading>
                                                <Heading>Subtotal</Heading>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ticketsToPurchase.map(ticket => (
                                                    <tr key={ticket.ticketId}>
                                                        <Detail style={{ fontWeight: 500 }}>{ticket.ticketType}</Detail>
                                                        <Detail>${(ticket.ticketPrice/100).toFixed(2)}</Detail>
                                                        <Detail>{ticket.ticketPurchaseCount}</Detail>
                                                        <Detail style={{ fontWeight: 500 }}>${(ticket.ticketSubtotal/100).toFixed(2)}</Detail>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </TableContainer>
                                
                                <BreakdownGrid> 
                                    <div>
                                        <SubtotalPre>Subtotal : </SubtotalPre>
                                        <SubtotalPre>Processing Fee : </SubtotalPre>
                                        <Subtotal>Order Total : </Subtotal>
                                    </div>
                                    <div>
                                        <SubtotalPre left> ${(this.props.location.state.subtotal/100).toFixed(2)}</SubtotalPre>
                                        <SubtotalPre left> ${((this.props.location.state.subtotal/100) * 0.12).toFixed(2)}</SubtotalPre>
                                        <Subtotal left> ${(total/100).toFixed(2)}</Subtotal>
                                    </div>
                                </BreakdownGrid>
                                <Clear />
                                <hr style={{ opacity: '0.3', margin: '60px 0' }} />
                            </OrderSummaryContainer>
                            </Fade>
                            
                            <PaymentInformationContainer>
                            <Fade bottom>
                                <SectionTitle>General Information</SectionTitle>
                                <Subtitle>Please enter your contact information</Subtitle>
                                <GeneralForm>
                                    <FormLable>First and Last Name</FormLable>
                                    <FormInput type="text" name="fullName" placeholder="John Doe" onChange={this.handleInput} value={this.state.fullName}/>

                                    <FormLable>Email Address</FormLable>
                                    <FormInput type="email" name="email" placeholder="Email Address" onChange={this.handleInput} value={this.state.email}/>
                                </GeneralForm>
                                
                            </Fade>
                            <hr style={{ opacity: '0.3', margin: '60px 0' }} />
                            
                            <Fade bottom>
                                <SectionTitle>Payment Information</SectionTitle>
                                <Subtitle>Payments are always processed using a secure server</Subtitle>
                                <Elements>
                                    <CheckoutForm fullName={this.state.fullName} email={this.state.email} amount={total} pump={pump} ticketsToPurchase={ticketsToPurchase} />
                                </Elements>
                            </Fade>
                            
                            </PaymentInformationContainer>
                            <FaStripe style={{textAlign: 'center', display: 'block', margin: '0 auto', fontSize: '90px', color: 'lightgray'}}/>
                            <PaymentDisclaimer>Secure payment is guaranteed by Stripe Payments.</PaymentDisclaimer>
                        </CheckoutContainer>
                    </StripeProvider>
                </Layout>
            );
        } else {
            return (
                <Layout>How did you get here? Go <Link to="/">home</Link></Layout>
            )
        }
    }
}

export default CheckoutPage;
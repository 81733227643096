import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

//NPM
import Loader from 'react-loader-spinner'
import axios from 'axios'
import styled from 'styled-components'

//Parse
import { CreateTicketHolder, currentUserCheck } from '../services/parse-bridge'

import './checkout-form.css'
import { navigate } from '@reach/router';
import { Mixpanel } from '../components/mixpanel'

const baseUrl = 'https://nameless-shore-28390.herokuapp.com'

const style = {
    base: {
        iconColor: '#c4f0ff',
        color: '#0e0e0e;',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '20px',
        fontSmoothing: 'antialiased',

        ':-webkit-autofill': {
            color: '#fce883',
        },
        '::placeholder': {
            color: '#87BBFD',
        },
    },
    invalid: {
        iconColor: 'red',
        color: 'red',
    },
}

const Spinner = styled.div`
    text-align: center;
    margin: 15px 0;
    font-weight: 100;
    font-size: 14px;
`

class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }

        this.submit = this.submit.bind(this);

    }

    componentDidMount() {
    }

    async mapTickets(ticketsToPurchase, pump) {
        var tickObjs = []
        var i
        var j

        //Create objects for each ticket to send to the API
        for(j=0; j<ticketsToPurchase.length; j++) {
            console.log(`Creating ${ticketsToPurchase[j].ticketType} tickets`)
            for(i=0; i<ticketsToPurchase[j].ticketPurchaseCount; i++) {
                console.log(`Creating ${(i + 1)} ${ticketsToPurchase[j].ticketType} ticket`)

                //Append the event name to each ticket
                tickObjs.push({
                    ...ticketsToPurchase[j],
                    eventName: pump.name,
                })
            }
        }
        return tickObjs
    }

    sendTIcketsToAPI(ticketsToPurchase, pump, fullName, email) {

        //Add the authorization header so we can make a request to the WDP API
        const AUTH_TOKEN = localStorage.getItem('wdptoken');
        axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

        //These are the selected ticket types and amounts
        console.log(ticketsToPurchase)

        this.mapTickets(ticketsToPurchase, pump).then(newTickets=> {
            //Send tickets off to be created
            CreateTicketHolder(newTickets, pump, fullName, email, ticketsToPurchase)
        })

        
    }

    async submit(ev) {

        console.log(this.props)

        if (this.props.fullName == "" || this.props.email == "") {
            alert("Please ensure you complete the General Information form")
            return
        }

        Mixpanel.track('Payment button hit', {eventName: this.props.pump.name})

        this.setState({ loading: true })

        // User clicked submit
        console.log("creating token")
        let token = await this.props.stripe.createToken({name: this.props.fullName});
        console.log(token)

        //Check if there was an error in creating the token
        if (token.error) {
            this.setState({ loading: false })
            alert(token.error.message)
            return
        }

        Mixpanel.track('Token Created', {eventName: this.props.pump.name})

        //We need to remove the authorization header in order to sent requet to payment api
        delete axios.defaults.headers.common["Authorization"]

        console.log("creating charge with token")

        //Send the charge and await response
        console.log(`Creating a charge of $ ${(this.props.amount/100).toFixed(2)} for ${this.props.fullName}`)

        axios.post(`${baseUrl}/webcharge` ,{
            amount: (this.props.amount).toFixed(0),
            stripeToken: token.token.id,
            receipt_email: this.props.email,
            name: this.props.fullName,
            pumpName: this.props.pump.name
        }).then(response =>{
            console.log(response)
            console.log(`Successful payment : ${response}`)
            
            Mixpanel.track('Successful payment', {eventName: this.props.pump.name, name: this.props.fullName})

            //Call the ticket creation API
            console.log("Creating tickets now")
            this.sendTIcketsToAPI(this.props.ticketsToPurchase, this.props.pump, this.props.fullName, this.props.email)            // navigate(`/payment-success/?eid=${this.props.pump.id}`)
        }).catch(error=>{
            this.setState({ loading: false })
            alert('Please ensure that you entered your information correctly and try again')
            return

        })
    }

    render() {
        return (
            <div className="checkout">
                <CardElement
                    hidePostalCode />
                <button onClick={this.submit}>Complete Payment</button>
                {this.state.loading && <Spinner><Loader type="Oval" color="#60e05c" height="50" width="50" />We're processing your payment...</Spinner>}
            </div>
        );
    }
}

export default injectStripe(CheckoutForm);